import { graphql } from 'gatsby';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import BlogCardItem from '../components/elements/BlogCardItem';
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import { usePageContext } from '../pageContext';
import { Articles } from '../providers/Articles';

function ArticlesPage({ location, data }) {

    const languageData = useTranslation()[1].store.data;

    const { lang } = usePageContext();

    const echo = (expression) => {
        return languageData[lang]['articlesPage'][expression];
    }

    return (
        <Fragment>
            <Seo title={`Hanniel TSASSE - ${lang == 'fr' ? 'Articles du blog' : 'Blog posts'}`} lang={lang} description="" />

            <Layout data={data} lang={lang}>
                <div style={{ marginTop: '25px' }}></div>
                {/* <!-- container --> */}
                <div class="container-fluid">


                    {/* <!-- col --> */}
                    <div className="col-lg-12">

                        {/* <!-- section title --> */}
                        <div className="art-section-title">
                            {/* <!-- title frame --> */}
                            <div className="art-title-frame">
                                {/* <!-- title --> */}
                                <h2>{lang == 'fr' ? 'Quoi de neuf ?' : "What's New ?"}</h2>
                            </div>
                            {/* <!-- title frame end --> */}
                        </div>
                        {/* <!-- section title end --> */}

                    </div>
                    {/* <!-- col end --> */}

                    {/* <!-- row --> */}
                    <div class="row p-30-0">
                        {Articles.map((article, index) => <BlogCardItem lang={lang} key={index} article={article} />)}
                    </div>
                    {/* <!-- row end --> */}

                </div>
                {/* <!-- container end --> */}

                {/* <!-- container --> */}
                <div class="container-fluid">

                    {/* <!-- row --> */}
                    <div class="row">

                        {/* <!-- col --> */}
                        <div class="col-lg-12">

                            {/* <!-- pagination --> */}
                            <div class="art-a art-pagination">
                                {/* <!-- button --> */}
                                <a href="#" class="art-link art-color-link art-w-chevron art-left-link"><span>{lang == 'fr' ? 'Page précédente' : 'Previous page'}</span></a>
                                <div class="art-pagination-center art-m-hidden">
                                    <a href="#"></a>
                                    <a href="#">1</a>
                                    <a class="art-active-pag" href="#">2</a>
                                    <a href="#">3</a>
                                    <a href="#">...</a>
                                </div>
                                {/* <!-- button --> */}
                                <a href="#" class="art-link art-color-link art-w-chevron"><span>{lang == 'fr' ? 'Page suivante' : 'Next page'}</span></a>
                            </div>
                            {/* <!-- pagination end --> */}

                        </div>
                        {/* <!-- col end --> */}

                    </div>
                    {/* <!-- row end --> */}

                </div>
                {/* <!-- container end --> */}
            </Layout>
        </Fragment>
    )
}

export default ArticlesPage;

export const query = graphql`
  query ArticlesPageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }`