import UploadImageArticle from "../components/articles/UploadImage";

export const Articles = [
    {
        slug: 'comment-afficher-image-import-javascript',
        title: 'Comment afficher une image téléchargée sur la page en utilisant Javascript ?',
        description: "Cet articles vous présente comment afficher une image après l'avoir importée en utilisatent du JavaScript.",
        category: 'HTML, JavaScript astuces',
        image: 'assets/img/blog/1.jpg',
        Component: UploadImageArticle
    }
];