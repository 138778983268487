import React from 'react'
import Link from '../Link'

function BlogCardItem({ article, type, lang }) {
    return (
        <div className={type == 'swipe' ? "swiper-slide" : 'col-lg-4'}>

            <div className="art-a art-blog-card">
                {/* <!-- post cover --> */}
                <Link to={`/articles/${article.slug}`} className="art-port-cover">
                    {/* <!-- img --> */}
                    <img src={`/${article.image}`} alt="blog post" />
                </Link>
                {/* <!-- post cover end --> */}
                {/* <!-- post description --> */}
                <div className="art-post-description">
                    {/* <!-- title --> */}
                    <Link to={`/articles/${article.slug}`}>
                        <h5 className="mb-15">{article.title}</h5>
                    </Link>
                    {/* <!-- text --> */}
                    <div className="mb-15">{article.description}</div>
                    {/* <!-- link --> */}
                    <Link to={`/articles/${article.slug}`} className="art-link art-color-link art-w-chevron">{lang == 'fr' ? "Lire plus" : "Read More"}</Link>
                </div>
                {/* <!-- post description end --> */}
            </div>
        </div>
    )
}

export default BlogCardItem
