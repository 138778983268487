import React from 'react'

function UploadImageArticle() {
    return (
        <div className="col-lg-12">

            {/* <!-- post text --> */}
            <div className="art-a art-card">
                <p>&nbsp;&nbsp;L'affichage de l'image dans le champ de formulaire HTML lors du téléchargement du fichier est très facile avec l'utilisation de JavaScript. Ajoutons un peu plus d'effet pour le rendre plus attrayant.</p>
                <h3>&nbsp;Masquer le bouton de téléchargement de fichier de la page HTML et le remplacer par un lien texte ou icône</h3><br />

                <p>&nbsp;&nbsp;Si vous jetez un œil à la plupart des plateformes sociales, elles ont une chose en commun liée au téléchargement de fichiers et c'est le bouton de téléchargement de fichier ou d'image qui n'est pas présent dans le formulaire et un lien texte ou icône fait le travail. Cela nous aide à nous débarrasser du bouton de téléchargement de fichier ennuyeux de notre page HTML tout en effectuant le travail de téléchargement de fichier. Voyons comment c'est fait.</p>

                <deckgo-highlight-code>
                    <code slot="code">{'<input type="file" accept="image/*" name="image" id="file" style="display: none;" />'}</code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Si vous souhaitez autoriser le téléchargement de tous les types d'images dans votre formulaire, vous pouvez le laisser comme indiqué ci-dessus. Vous pouvez remplacer les propriétés sur l'attribut <code>accept</code> si vous souhaitez spécifier les extensions d'image à télécharger via votre formulaire comme indiqué ci-dessous.</p>

                <deckgo-highlight-code>
                    <code slot="code">{'<input type="file" accept="image/gif, image/jpeg, image/png" name="image" id="file" style="display: none;" />'}</code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Nous avons besoin d'un nom pour le champ de saisie pour l'ajouter à la base de données, nous lui avons donc fourni une image de nom. Fournissez un identifiant à votre <code>input file</code> et ajoutez un style <code><b>display:</b>none</code> ou <code><b>visibility:</b>hidden</code>. Il est préférable d'utiliser la propriété <i>display</i> ici car elle n'occupera pas l'espace de votre page.</p>

                <h3>&nbsp;&nbsp;Créer un <code>label</code> pour le champ du fichier</h3><br />

                <p>&nbsp;&nbsp;&nbsp;&nbsp;Maintenant, créez un <code>label</code> avec le même id ajouté dans l'attribut <code>for</code> pour le connecter au champ du fichier comme indiqué ci-dessous et vous avez terminé.</p>

                <deckgo-highlight-code>
                    <code slot="code">{'<p><label for="file" style="cursor: pointer;">Charger l\'image</label></p>'}</code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Maintenant, si vous cliquez sur le <i>label</i>, l'explorateur de fichiers s'ouvrira comme il le ferait en cliquant sur le bouton d'entrée de fichier.</p>

                <p>&nbsp;&nbsp;Mais, une fois que vous masquez ces boutons de téléchargement de fichier de votre page HTML, les informations sur le fichier affichées à côté du bouton lors du téléchargement de fichier sont également masquées, ce qui peut rendre les utilisateurs confus quant à savoir si l'image est téléchargée ou non. Dans de tels cas, nous pouvons afficher les images téléchargées pour leur commodité ainsi que pour améliorer les perspectives de la page de formulaire.</p>

                <p>&nbsp;&nbsp;Alors, ajoutons un élément HTML <code>{'<img />'}</code> pour afficher l'image téléchargée.</p>

                <deckgo-highlight-code>
                    <code slot="code">{'<img id="output" width="200" />	'}</code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Ici, nous avons ajouté une balise HTML <code>{'<img />'}</code> sans source mais avec un identifiant qui récupérera la source de l'image à partir de javascript et nous avons défini que c'était 200px.</p>

                <p>&nbsp;&nbsp;Afin d'activer un événement défini dans n'importe quel code JavaScript, nous aurons besoin d'un gestionnaire d'événements dans votre élément HTML, nous l'ajouterons donc à notre champ du fichier.</p>

                <deckgo-highlight-code>
                    <code slot="code">{'<input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" style="display: none;" />'}</code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Ici, <code>loadFile(event)</code> est notre gestionnaire d'événements qui sera activé une fois que la valeur dans le champ de saisie est modifiée afin que le changement dans ce champ de saisie soit notre événement.</p>

                <h3>&nbsp;&nbsp;Afficher l'image sur la page</h3><br />

                <p> Ajoutons le code JavaScript qui va afficher l'image dans l'élément <code>{'<img id="output">'}</code>...</p>

                <deckgo-highlight-code line-numbers>
                    <code slot="code">
                        {`<script>\n  var loadFile = function(event) {\n    var image = document.getElementById('output');\n    image.src = URL.createObjectURL(event.target.files[0]);\n  };\n</script>`}
                    </code>
                </deckgo-highlight-code>

                <p>&nbsp;&nbsp;Ici, notre gestionnaire d'événements contiendra une valeur qui sera récupérée via le fichier image en cours de téléchargement via le bouton de fichier d'entrée en tant qu'URL dont nous avons besoin pour afficher l'image dans notre page Web.</p>

                <h3>&nbsp;&nbsp;Bloc de code complet dans son ensemble requis pour afficher l'image téléchargée en HTML en utilisant javascript</h3>

                <deckgo-highlight-code line-numbers>
                    <code slot="code">{`<p><input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" style="display: none;"></p>\n<p><label for="file" style="cursor: pointer;">Charger l\'image</label></p>\n<p><img id="output" width="200" /></p>\n\n<script>\n  var loadFile = function(event) {\n    var image = document.getElementById('output');\n    image.src = URL.createObjectURL(event.target.files[0]);\n  };\n</script>`}</code>
                </deckgo-highlight-code>

                <p>De cette façon, vous pouvez afficher le fichier téléchargé en html en utilisant javascript de manière attrayante.</p>

            </div>
            {/* <!-- post text end --> */}

        </div>
    )
}

export default UploadImageArticle
